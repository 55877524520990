import React from 'react';
import Layout from 'components/Layout';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
// import Faq from 'components/Faq';
import ArchivePostSingle from 'components/ArchivePostSingle';
import Meta from 'components/Meta';
import SupportHeader from 'components/SupportHeader';
import Scrollspy from 'components/Scrollspy';
// import theme from 'styles/theme';
// import Main from 'styles/supportInner';
// import { BlogListSupport } from 'styles/support';
import SupportInnerNav from '../../components/SupportInnerNav/SupportInnerNav';
import 'styles/supportInner.scss';
const SupportToolsAndTips = ({
  data: {
    allContentfulBlogPost: { edges: allPosts = [] },
  },
  location,
}) => (
  <Layout hideHeader location={location}>
    {({ toggleContactUs }) => (
      <>
        <Meta
          title="Support documentation 🔨 | ruttl"
          description=" ruttl simplifies website reviewing, enabling easy comments and live edits. Invite stakeholders for precise, contextual feedback on pixel-perfect designs."
          url="https://ruttl.com/support/"
        />
        <main className="supportinner-styed-main">
          <SupportHeader toggleContactUs={toggleContactUs} />
          <section className="supportinner-hero">
            <div className="container">
              <h1>Tools and tips:</h1>
            </div>
          </section>
          <SupportInnerNav />
          <section className="support-inner-block">
            <Scrollspy>
              <div className="flex-1 support-inner-content">
                <img src="/assets/img/support-get-started.png" alt="" />

                <h2
                  data-spy-title="Desktop and Mobile modes"
                  data-spy-id="desktop-and-mobile-modes">
                  Desktop and Mobile modes
                </h2>

                <p>
                  You can switch between desktop and mobile modes on ruttl. To
                  do so, on the bottom bar, there are 2 icons on the left side,
                  between Version and Share. By default, web pages will load in
                  Desktop mode only. Clicking on the mobile icon will load the
                  mobile view of the webpage, and clicking back on Desktop icon
                  will load the desktop view. Comments and edits in ruttl are
                  kept separate for mobile and desktop modes.
                </p>

                <h3
                  data-spy-title="Versioning in ruttl"
                  data-spy-id="versioning-in-ruttl">
                  Versioning in ruttl
                </h3>

                <p>
                  You can create multiple versions of the same webpage in Ruttl.
                  These can be helpful in showing different fonts, content,
                  images, etc on the same section to the client. To do so, open
                  any webpage in Ruttl and go to the bottom left hand corner of
                  the screen. By default, Ruttl will load all web pages in
                  Version 1. To add a version, click on the ‘Version 1’, and
                  then click on ‘Add latest version’ in the upward submenu. The
                  latest version of your webpage will appear in Ruttl, and you
                  can access it anytime in this submenu itself.
                </p>

                <h3
                  data-spy-title="Rename, delete or share individual versions as a link"
                  data-spy-id="rename-delete-or-share-individual-versions-as-a-link">
                  Rename, delete or share individual versions as a link
                </h3>

                <p>
                  On any version, go to the menu, click on ‘Rename’ and rename
                  this version. Click on ‘Share’ to share this individual
                  version with your client or other collaborators. You can also
                  delete versions by visiting the same version menu and clicking
                  on ‘Delete’. Versions only work in website feedback projects.
                </p>

                <h3 data-spy-title="Integrations" data-spy-id="integrations">
                  Integrations
                </h3>
                <p>
                  <>
                    Currently we have integrated with Jira, Slack, Trello,
                    ClickUp, Zapier, asana and Unsplash. We also have our custom
                    webhook, in case you’d like to integrate with any other work
                    tool of your preference. In the future, we also plan to
                    integrate with many more tools. For more details, please
                    visit our
                    <Link to="/integrations/">&nbsp;integrations&nbsp;</Link>
                    page.
                  </>
                </p>

                <h3
                  data-spy-title="Keyboard shortcuts"
                  data-spy-id="keyboard-shortcuts">
                  Keyboard shortcuts
                </h3>
                <p>
                  Make your access around ruttl easier and quicker. On your main
                  dashboard, just go to the top-right hand side question mark
                  icon (named Need Help) &gt; Keyboard Shortcuts. You will be
                  able to see all of your keyboard shortcuts here.
                </p>
                <h3
                  data-spy-title="Renaming project or webpage"
                  data-spy-id="renaming-project-or-webpage">
                  Renaming project or webpage
                </h3>
                <p>
                  To rename any project or webpage, hover over the name of the
                  project or webpage. Click on the text area, rename it as
                  required, and then press Enter/Return key. Your project or
                  webpage will be successfully renamed.
                </p>

                <h3
                  data-spy-title="Notifications in ruttl"
                  data-spy-id="notifications-in-ruttl">
                  Notifications in ruttl
                </h3>

                <p>
                  We have enabled notifications in ruttl. You will receive
                  notifications in the following situations:
                </p>

                <ul>
                  <li>When someone adds you to their project</li>
                  <li>
                    When someone adds a comment in the project, which you are a
                    part of
                  </li>
                  <li>When someone mentions you in a comment in the project</li>
                  <li>When someone replies to your comment in the project</li>
                  <li>
                    When someone makes edits in the project, which you are a
                    part of
                  </li>
                </ul>
                <p>
                  These notifications will be shown in the top right corner of
                  the screen on the main dashboard, and even inside the project.
                  Clicking on any notification will take you to that position,
                  where the action has been done, i.e. comment, edit, project.
                </p>
                <h3
                  data-spy-title="Configure email notifications"
                  data-spy-id="configure-email-notifications">
                  Configure email notifications
                </h3>
                <p>
                  You can configure which type of emails you would like to
                  receive from ruttl. To do so, on the main dashboard after
                  logging in, simply go to ‘Account Settings’ from the top right
                  hand corner menu. When you scroll down, you will find
                  ‘Notifications’. Here, you can select or unselect the boxes
                  for which you would want the email notifications, and it would
                  be auto-saved.
                </p>

                <h3
                  data-spy-title="Account settings"
                  data-spy-id="account-settings">
                  Account settings
                </h3>
                <p>
                  You can access account settings of ruttl in the main menu
                  itself. Once you login, go to the main menu on the top right
                  side of the screen and select Account Settings. From here, you
                  can perform the following actions:
                </p>

                <ul>
                  <li>Change avatar</li>
                  <li>Change display name</li>
                  <li>Update password</li>
                  <li>Watch our demo video</li>
                  <li>Join our Slack community</li>
                  <li>Check integrations available on ruttl</li>
                  <li>Upgrade your ruttl account</li>
                  <li>Change notifications for receiving emails from ruttl</li>
                  <li>
                    Contact us for account related questions, reporting bugs,
                    recommending a new feature, billing, sales, reporting abuse
                  </li>
                  <li>Delete your ruttl account</li>
                </ul>

                <h3
                  data-spy-title="Sending feedback"
                  data-spy-id="sending-feedback">
                  Sending feedback
                </h3>

                <p>
                  If you ever wish to send feedback to us regarding your
                  experience with ruttl, we have made it really simple for you.
                  On the main dashboard, you can click on the ‘Question-mark’
                  button, take a screenshot and write your feedback in the text
                  box. This feedback directly reaches our support team, and we
                  ensure that it is solved in the least time possible for our
                  users. Alternatively, you can also send us an email with your
                  screenshot and issue to&nbsp;
                  <a href="mailto:support@ruttl.com.">support@ruttl.com.</a>
                </p>

                <h3
                  data-spy-title="In-app contact us"
                  data-spy-id="in-app-contact-us">
                  In-app contact us
                </h3>
                <p>
                  If you ever wish to contact us, we have made it simple for you
                  to do so. On the main dashboard, to the bottom left side of
                  the screen, you will find a ‘Need help’ button. Click on the
                  same to contact us for the following reasons:
                </p>
                <ul>
                  <li>Account related questions</li>
                  <li>Upgrading your account</li>
                  <li>Reporting bugs</li>
                  <li>Recommending a new feature</li>
                  <li>Billing</li>
                  <li>Sales</li>
                  <li>Reporting abuse</li>
                  <li>Something else regarding ruttl</li>
                </ul>
                <p>
                  Your message directly reaches our Support team, and we shall
                  resolve your query at the earliest possible.
                </p>
              </div>
            </Scrollspy>
          </section>
          <main className="blog-list-support">
            <section
              className="bg-lightGrey"
              style={{
                // backgroundColor: theme.colors.lightGrey,
                padding: '10px 0',
              }}>
              <div className="container">
                <div className="other-articles-block">
                  <h3>Popular Articles</h3>
                  <ul>
                    {allPosts.map(({ node }) => (
                      <li key={node.id}>
                        <ArchivePostSingle
                          link={`/blog/${node.slug}/`}
                          title={node.title}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </section>
          </main>
          {/* <Faq id="faq" /> */}
        </main>
      </>
    )}
  </Layout>
);

SupportToolsAndTips.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default SupportToolsAndTips;

export const pageQuery = graphql`
  query SupportToolsAndTipsQuery {
    allContentfulBlogPost(
      filter: { knowledgeBox: { eq: false } }
      limit: 10
      sort: { order: DESC, fields: publishDate }
    ) {
      edges {
        node {
          id
          title
          slug
          publishDate(formatString: "MMM DD, YYYY")
          archiveThumbnail {
            fluid(
              maxWidth: 600
              maxHeight: 456
              quality: 70
              resizingBehavior: THUMB
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          excerpt {
            excerpt
          }
          knowledgeBox
          category {
            name
          }
        }
      }
    }
  }
`;
